// i18next-extract-mark-ns-start pricing

import {
  CheckMark,
  MobilePlanLabel,
  PlanAction,
  PlanFeatures,
  PlanHeader,
  PlanLabel,
  PlanPrice,
  PlanTitle
} from './PricingSection';
import {Link, Trans} from 'gatsby-plugin-react-i18next';
import {AnchorLink} from '../AnchorLink';
import {currency, percent} from 'utils/formatters';
import React from 'react';
import {SignUpButton} from '../SignUpButton';
import check from 'images/check_payment_gateway.svg';
import cross from 'images/cross_payment_gateway.svg';
import {BlogLink} from '../links/Blog';
import styled from 'styled-components';
import {StyledLi} from 'components/StyledSx';

type PlanDetailsProps = {
  id: string;
  planHeader: JSX.Element;
  planFeatures: JSX.Element;
  planAction: JSX.Element;
};

const scrollToVatExempt = () => {
  const vatExempt = document.getElementById('vat-exempt') as HTMLElement;
  vatExempt.scrollIntoView({behavior: 'smooth', block: 'center'});
};

const VatExemptLink = styled.div`
  cursor: pointer;
  display: inline;
  color: #9d74b9;
  font-size: 1.2rem;
`;

export const ListRow = styled(StyledLi)`
  padding: 10px 40px;
  align-items: center;
  min-height: 66px;
  display: flex;
  span {
    width: 24%;
  }
  :nth-child(even) {
    background: #f5f5f5;
  }
  img {
    margin: 0 8px 0 0;
  }
`;

export const PlanDetails: (language: string) => PlanDetailsProps[] = (language = 'en-US') => [
  {
    id: 'moneiX',
    planHeader: (
      <PlanHeader>
        <PlanTitle>MONEI X</PlanTitle>
        <Trans parent={PlanLabel}>Dynamic Pricing</Trans>
        <PlanPrice>
          <Trans parent="p">
            <AnchorLink href="https://support.monei.com/hc/articles/4416520191249">
              Interchange ++
            </AnchorLink>
          </Trans>
          <VatExemptLink onClick={scrollToVatExempt}>{percent.format(0.1 / 100)} *</VatExemptLink>
          <Trans parent="p" style={{fontSize: '0.8rem'}}>
            (Visa, Mastercard, Diners Club International, Discover, JCB, UnionPay)
          </Trans>
        </PlanPrice>
        <PlanPrice>
          <Trans parent="p">Scaled platform usage from:</Trans>
          <VatExemptLink onClick={scrollToVatExempt}>
            <Trans>
              {{value1: percent.format(0.15 / 100)}} to {{value2: percent.format(1.19 / 100)}}
            </Trans>{' '}
            + {currency(language).format(0.24)} *
          </VatExemptLink>
        </PlanPrice>
      </PlanHeader>
    ),
    planFeatures: (
      <PlanFeatures>
        <ListRow sx={{flexDirection: {lg: 'column'}, alignItems: {lg: 'flex-start'}}}>
          <Trans parent={MobilePlanLabel}>Setup Fee</Trans>
          <Trans>Free</Trans>
        </ListRow>
        <ListRow
          sx={{flexDirection: {lg: 'column'}, alignItems: {lg: 'flex-start'}, height: '122.75px'}}>
          <Trans parent={MobilePlanLabel}>Daily Fee</Trans>
          <span>{currency(language).format(0.1)}</span>
        </ListRow>
        <ListRow sx={{flexDirection: {lg: 'column'}, alignItems: {lg: 'flex-start'}}}>
          <Trans parent={MobilePlanLabel}>Payment Methods</Trans>
          <Trans>All payment methods</Trans>
        </ListRow>
        <ListRow
          sx={{flexDirection: {lg: 'column'}, alignItems: {lg: 'flex-start'}, height: '112.75px'}}>
          <Trans parent={MobilePlanLabel}>Settlement</Trans>
          <Trans>7 days (up to 30, based on your account's risk score)</Trans>
        </ListRow>
        <ListRow sx={{flexDirection: {lg: 'column'}, alignItems: {lg: 'flex-start'}}}>
          <Trans parent={MobilePlanLabel}>Subscription engine</Trans>
          <Trans>0 €</Trans>
        </ListRow>
        <ListRow sx={{flexDirection: {lg: 'column'}, alignItems: {lg: 'flex-start'}}}>
          <Trans parent={MobilePlanLabel}>All Features + Integrations</Trans>
          <Trans>Free</Trans>
        </ListRow>
        <ListRow sx={{flexDirection: {lg: 'column'}, alignItems: {lg: 'flex-start'}}}>
          <Trans parent={MobilePlanLabel}>Unlimited API Access</Trans>
          <Trans>Free</Trans>
        </ListRow>
        <ListRow>
          <CheckMark src={check} alt="Fully 3D Secure" title="Fully 3D Secure" width={18} />
          <MobilePlanLabel>
            <BlogLink slug="what-is-3d-secure-and-its-advantages-for-e-commerce">
              <Trans>Fully 3D Secure</Trans>
            </BlogLink>
          </MobilePlanLabel>
        </ListRow>
        <ListRow>
          <CheckMark
            src={cross}
            alt="Acquirer Routing Engine"
            title="Acquirer Routing Engine"
            width={18}
          />
          <Trans parent={MobilePlanLabel} style={{position: 'relative', top: '-6px'}}>
            Acquirer Routing Engine
          </Trans>
        </ListRow>
        <ListRow sx={{height: '89.56px'}}>
          <CheckMark
            src={cross}
            alt="Processor Routing Engine"
            title="Processor Routing Engine"
            width={18}
          />
          <Trans parent={MobilePlanLabel} style={{position: 'relative', top: '-6px'}}>
            Smart Transaction Routing & Cascading
          </Trans>
        </ListRow>
        <ListRow>
          <CheckMark
            src={cross}
            alt="Payments Orchestration"
            title="Payments Orchestration"
            width={18}
          />
          <Trans parent={MobilePlanLabel} style={{position: 'relative', top: '-6px'}}>
            Payments Orchestration
          </Trans>
        </ListRow>
        <ListRow>
          <CheckMark
            src={check}
            alt="Proprietary MPI Server"
            title="Proprietary MPI Server"
            width={18}
          />
          <Trans parent={MobilePlanLabel}>Proprietary MPI Server</Trans>
        </ListRow>
        <ListRow sx={{height: '89.56px'}}>
          <CheckMark
            src={check}
            alt="PCI Level1 Card Vaulting"
            title="PCI Level1 Card Vaulting"
            width={18}
          />
          <Trans parent={MobilePlanLabel}>Fully PCI Level1 Compliant Card Vaulting</Trans>
        </ListRow>
        <ListRow>
          <CheckMark src={check} alt="Scheme tokenization" title="Scheme tokenization" width={18} />
          <Trans parent={MobilePlanLabel}>Scheme tokenization + Card updater</Trans>
        </ListRow>
        <ListRow>
          <CheckMark
            src={check}
            alt="3D Secure Rules (Smart Exceptions)"
            title="3D Secure Rules (Smart Exceptions)"
            width={18}
          />
          <Trans parent={MobilePlanLabel}>3D Secure Rules (Smart Exceptions)</Trans>
        </ListRow>
        <ListRow sx={{flexDirection: {lg: 'column'}, alignItems: {lg: 'flex-start'}}}>
          <Trans parent={MobilePlanLabel}>Refunds</Trans>
          <Trans>Free</Trans>
        </ListRow>
        <ListRow
          sx={{
            flexDirection: {lg: 'column'},
            alignItems: {lg: 'flex-start'},
            height: {lg: '89.56px'}
          }}>
          <Trans parent={MobilePlanLabel}>Chargebacks</Trans>
          {currency(language).format(17.0)} <Trans>Per Chargeback</Trans>
        </ListRow>
        <ListRow
          sx={{
            flexDirection: {lg: 'column'},
            alignItems: {lg: 'flex-start'},
            height: {lg: '89.56px'}
          }}>
          <Trans parent={MobilePlanLabel}>Dashboard Users</Trans>
          <Trans>1 Included, Additional Seats:</Trans> {currency(language).format(0.16)}{' '}
          <Trans>/ Day</Trans>
        </ListRow>
        <ListRow sx={{height: {lg: '89.56px'}}}>
          <CheckMark
            src={cross}
            alt="Support for payment orchestration setup & configuration"
            title="Support for payment orchestration setup & configuration"
            width={18}
          />
          <Trans parent={MobilePlanLabel} style={{position: 'relative', top: '-6px'}}>
            High Priority Support (Dedicated Slack Channel)
          </Trans>
        </ListRow>
      </PlanFeatures>
    ),
    planAction: (
      <PlanAction>
        <SignUpButton variant="light">
          <Trans>Open an Account</Trans>
        </SignUpButton>
      </PlanAction>
    )
  },
  {
    id: 'moneyPlus',
    planHeader: (
      <PlanHeader>
        <PlanTitle>MONEI PLUS</PlanTitle>
        <Trans parent={PlanLabel}>Use your Processor or Acquirer </Trans>
        <Trans parent="p">
          <Link to="/features/payments-orchestration/">Payments Orchestration Platform (POP)</Link>
        </Trans>
        <PlanPrice>
          <Trans parent="p">Scaled platform usage from:</Trans>
          <VatExemptLink onClick={scrollToVatExempt}>
            <Trans>
              {{value1: percent.format(0.15 / 100)}} to {{value2: percent.format(1.19 / 100)}}
            </Trans>{' '}
            + {currency(language).format(0.24)}*
          </VatExemptLink>
          <Trans parent="p">
            per successful transaction with <b>ANY</b> Payment Method
          </Trans>
        </PlanPrice>
      </PlanHeader>
    ),
    planFeatures: (
      <PlanFeatures>
        <ListRow sx={{flexDirection: {lg: 'column'}, alignItems: {lg: 'flex-start'}}}>
          <Trans parent={MobilePlanLabel}>Setup Fee</Trans>
          <Trans>Free</Trans>
        </ListRow>
        <ListRow
          sx={{flexDirection: {lg: 'column'}, alignItems: {lg: 'flex-start'}, height: '122.75px'}}>
          <Trans parent={MobilePlanLabel}>Daily Fee</Trans>
          <div style={{fontSize: '14px', lineHeight: '18px'}}>
            {currency(language).format(39.99)} <Trans>for connection with up to 2 processors</Trans>
            , {currency(language).format(19)}{' '}
            <Trans>
              for each additional processor (free until you process the first transaction)
            </Trans>
          </div>
        </ListRow>
        <ListRow sx={{flexDirection: {lg: 'column'}, alignItems: {lg: 'flex-start'}}}>
          <Trans parent={MobilePlanLabel}>Payment Methods</Trans>
          <Trans>All payment methods</Trans>
        </ListRow>
        <ListRow
          sx={{height: '112.75px', flexDirection: {lg: 'column'}, alignItems: {lg: 'flex-start'}}}>
          <Trans parent={MobilePlanLabel}>Settlement</Trans>
          <Trans>What Your Acquirer Offers You</Trans>
        </ListRow>
        <ListRow sx={{flexDirection: {lg: 'column'}, alignItems: {lg: 'flex-start'}}}>
          <Trans parent={MobilePlanLabel}>Subscription engine</Trans>
          <Trans>0 €</Trans>
        </ListRow>
        <ListRow sx={{flexDirection: {lg: 'column'}, alignItems: {lg: 'flex-start'}}}>
          <Trans parent={MobilePlanLabel}>All Features + Integrations</Trans>
          <Trans>Free</Trans>
        </ListRow>
        <ListRow sx={{flexDirection: {lg: 'column'}, alignItems: {lg: 'flex-start'}}}>
          <Trans parent={MobilePlanLabel}>Unlimited API Access</Trans>
          <Trans>Free</Trans>
        </ListRow>
        <ListRow>
          <CheckMark src={check} alt="Payment Gateway" title="Payment Gateway" width={18} />
          <MobilePlanLabel>
            <BlogLink slug="what-is-3d-secure-and-its-advantages-for-e-commerce">
              <Trans>Fully 3D Secure</Trans>
            </BlogLink>
          </MobilePlanLabel>
        </ListRow>
        <ListRow>
          <CheckMark
            src={check}
            alt="Acquirer Routing Engine"
            title="Acquirer Routing Engine"
            width={18}
          />
          <Trans parent={MobilePlanLabel}>Acquirer Routing Engine</Trans>
        </ListRow>
        <ListRow sx={{height: '89.56px'}}>
          <CheckMark
            src={check}
            alt="Processor Routing Engine"
            title="Processor Routing Engine"
            width={18}
          />
          <Trans parent={MobilePlanLabel}>Smart Transaction Routing & Cascading</Trans>
        </ListRow>
        <ListRow>
          <CheckMark
            src={check}
            alt="Payments Orchestration"
            title="Payments Orchestration"
            width={18}
          />
          <Trans parent={MobilePlanLabel}>Payments Orchestration</Trans>
        </ListRow>
        <ListRow>
          <CheckMark
            src={cross}
            alt="Proprietary MPI Server"
            title="Proprietary MPI Server"
            width={18}
          />
          <Trans parent={MobilePlanLabel} style={{position: 'relative', top: '-6px'}}>
            Proprietary MPI Server
          </Trans>
        </ListRow>
        <ListRow sx={{height: '89.56px'}}>
          <CheckMark
            src={check}
            alt="PCI Level1 Card Vaulting"
            title="PCI Level1 Card Vaulting"
            width={18}
          />
          <Trans parent={MobilePlanLabel}>Fully PCI Level1 Compliant Card Vaulting</Trans>
        </ListRow>
        <ListRow>
          <CheckMark src={check} alt="Scheme tokenization" title="Scheme tokenization" width={18} />
          <Trans parent={MobilePlanLabel}>Scheme tokenization + Card updater</Trans>
        </ListRow>
        <ListRow>
          <CheckMark
            src={cross}
            alt="3D Secure Rules (Smart Exceptions)"
            title="3D Secure Rules (Smart Exceptions)"
            width={18}
          />
          <Trans parent={MobilePlanLabel} style={{position: 'relative', top: '-6px'}}>
            3D Secure Rules (Smart Exceptions)
          </Trans>
        </ListRow>
        <ListRow sx={{flexDirection: {lg: 'column'}, alignItems: {lg: 'flex-start'}}}>
          <Trans parent={MobilePlanLabel}>Refunds</Trans>
          <Trans>Free</Trans>
        </ListRow>
        <ListRow
          sx={{
            flexDirection: {lg: 'column'},
            alignItems: {lg: 'flex-start'},
            height: {lg: '89.56px'}
          }}>
          <Trans parent={MobilePlanLabel}>Chargebacks</Trans>
          <Trans>What Your Acquirer Offers You</Trans>
        </ListRow>
        <ListRow
          sx={{
            flexDirection: {lg: 'column'},
            alignItems: {lg: 'flex-start'},
            height: {lg: '84.56px'}
          }}>
          <Trans parent={MobilePlanLabel}>Dashboard Users</Trans>
          <Trans>Unlimited</Trans>
        </ListRow>
        <ListRow sx={{height: {lg: '89.56px'}}}>
          <CheckMark
            src={check}
            alt="Support for payment orchestration setup & configuration"
            title="Support for payment orchestration setup & configuration"
            width={18}
          />
          <Trans parent={MobilePlanLabel}>High Priority Support (Dedicated Slack Channel)</Trans>
        </ListRow>
      </PlanFeatures>
    ),
    planAction: (
      <PlanAction>
        <SignUpButton variant="light">
          <Trans>Open an Account</Trans>
        </SignUpButton>
      </PlanAction>
    )
  },
  {
    id: 'moneiPay',
    planHeader: (
      <PlanHeader>
        <PlanTitle>MONEI Pay</PlanTitle>
        <Trans parent={PlanLabel}>Sell In-store</Trans>
        <PlanPrice>
          <Trans parent="p">Cards transactions</Trans>
          <VatExemptLink onClick={scrollToVatExempt}>{percent.format(0.65 / 100)} *</VatExemptLink>
          <Trans parent="p">per successful transaction</Trans>
          <p style={{fontSize: '0.8rem'}}>
            (Apple Pay, Google Pay, PayPal, Visa, Mastercard, Diners Club International, Discover, JCB, UnionPay)
          </p>
        </PlanPrice>
        <PlanPrice>
          <Trans parent="p">Bizum transactions</Trans>
          <VatExemptLink onClick={scrollToVatExempt}>{percent.format(0.9 / 100)} *</VatExemptLink>
          <Trans parent="p">per successful transaction</Trans>
        </PlanPrice>
      </PlanHeader>
    ),
    planFeatures: (
      <PlanFeatures>
        <ListRow sx={{flexDirection: {lg: 'column'}, alignItems: {lg: 'flex-start'}}}>
          <Trans parent={MobilePlanLabel}>Setup Fee</Trans>
          <Trans>Free</Trans>
        </ListRow>
        <ListRow
          sx={{flexDirection: {lg: 'column'}, alignItems: {lg: 'flex-start'}, height: '122.75px'}}>
          <Trans parent={MobilePlanLabel}>Daily Fee</Trans>
          <Trans>{currency(language).format(0.03)}</Trans>
        </ListRow>
        <ListRow sx={{flexDirection: {lg: 'column'}, alignItems: {lg: 'flex-start'}}}>
          <Trans parent={MobilePlanLabel}>Payment Methods</Trans>
          <Trans>All payment methods</Trans>
        </ListRow>
        <ListRow
          sx={{flexDirection: {lg: 'column'}, alignItems: {lg: 'flex-start'}, height: '112.75px'}}>
          <Trans parent={MobilePlanLabel}>Settlement</Trans>
          <Trans>24 h</Trans>
        </ListRow>
        <ListRow>
          <CheckMark src={cross} alt="Subscription engine" title="Subscription engine" width={18} />
          <Trans parent={MobilePlanLabel} style={{position: 'relative', top: '-6px'}}>
            Subscription engine
          </Trans>
        </ListRow>
        <ListRow sx={{flexDirection: {lg: 'column'}, alignItems: {lg: 'flex-start'}}}>
          <Trans parent={MobilePlanLabel}>All Features + Integrations</Trans>
          <Trans>Free</Trans>
        </ListRow>
        <ListRow sx={{flexDirection: {lg: 'column'}, alignItems: {lg: 'flex-start'}}}>
          <Trans parent={MobilePlanLabel}>Unlimited API Access</Trans>
          <Trans>Not applicable</Trans>
        </ListRow>
        <ListRow>
          <CheckMark src={check} alt="Fully 3D Secure" title="Fully 3D Secure" width={18} />
          <MobilePlanLabel>
            <BlogLink slug="what-is-3d-secure-and-its-advantages-for-e-commerce">
              <Trans>Fully 3D Secure</Trans>
            </BlogLink>
          </MobilePlanLabel>
        </ListRow>
        <ListRow>
          <CheckMark
            src={cross}
            alt="Acquirer Routing Engine"
            title="Acquirer Routing Engine"
            width={18}
          />
          <Trans parent={MobilePlanLabel} style={{position: 'relative', top: '-6px'}}>
            Acquirer Routing Engine
          </Trans>
        </ListRow>
        <ListRow sx={{height: '89.56px'}}>
          <CheckMark
            src={cross}
            alt="Processor Routing Engine"
            title="Processor Routing Engine"
            width={18}
          />
          <Trans parent={MobilePlanLabel} style={{position: 'relative', top: '-6px'}}>
            Smart Transaction Routing & Cascading
          </Trans>
        </ListRow>
        <ListRow>
          <CheckMark
            src={cross}
            alt="Payments Orchestration"
            title="Payments Orchestration"
            width={18}
          />
          <Trans parent={MobilePlanLabel} style={{position: 'relative', top: '-6px'}}>
            Payments Orchestration
          </Trans>
        </ListRow>
        <ListRow>
          <CheckMark
            src={check}
            alt="Proprietary MPI Server"
            title="Proprietary MPI Server"
            width={18}
          />
          <Trans parent={MobilePlanLabel}>Proprietary MPI Server</Trans>
        </ListRow>
        <ListRow sx={{height: '89.56px'}}>
          <CheckMark
            src={check}
            alt="PCI Level1 Card Vaulting"
            title="PCI Level1 Card Vaulting"
            width={18}
          />
          <Trans parent={MobilePlanLabel}>Fully PCI Level1 Compliant Card Vaulting</Trans>
        </ListRow>
        <ListRow>
          <CheckMark src={check} alt="Scheme tokenization" title="Scheme tokenization" width={18} />
          <Trans parent={MobilePlanLabel}>Scheme tokenization + Card updater</Trans>
        </ListRow>
        <ListRow>
          <CheckMark
            src={check}
            alt="3D Secure Rules (Smart Exceptions)"
            title="3D Secure Rules (Smart Exceptions)"
            width={18}
          />
          <Trans parent={MobilePlanLabel}>3D Secure Rules (Smart Exceptions)</Trans>
        </ListRow>
        <ListRow sx={{flexDirection: {lg: 'column'}, alignItems: {lg: 'flex-start'}}}>
          <Trans parent={MobilePlanLabel}>Refunds</Trans>
          <Trans>Free</Trans>
        </ListRow>
        <ListRow
          sx={{
            flexDirection: {lg: 'column'},
            alignItems: {lg: 'flex-start'},
            height: {lg: '89.56px'}
          }}>
          <Trans parent={MobilePlanLabel}>Chargebacks</Trans>
          {currency(language).format(17.0)} <Trans>Per Chargeback</Trans>
        </ListRow>
        <ListRow
          sx={{
            flexDirection: {lg: 'column'},
            alignItems: {lg: 'flex-start'},
            height: {lg: '89.56px'}
          }}>
          <Trans parent={MobilePlanLabel}>Dashboard Users</Trans>
          <Trans>1 Included, Additional Seats:</Trans> {currency(language).format(0.16)}{' '}
          <Trans>/ Day</Trans>
        </ListRow>
        <ListRow sx={{height: {lg: '89.56px'}}}>
          <CheckMark
            src={cross}
            alt="Support for payment orchestration setup & configuration"
            title="Support for payment orchestration setup & configuration"
            width={18}
          />
          <Trans parent={MobilePlanLabel} style={{position: 'relative', top: '-6px'}}>
            High Priority Support (Dedicated Slack Channel)
          </Trans>
        </ListRow>
      </PlanFeatures>
    ),
    planAction: (
      <PlanAction>
        <SignUpButton variant="light">
          <Trans>Open an Account</Trans>
        </SignUpButton>
      </PlanAction>
    )
  }
];
