// i18next-extract-mark-ns-start pricing
import {AnchorLink} from 'components/AnchorLink';
import {
  Plan,
  PlansContainer,
  PlansList,
  PlansTable,
  PricingBackground,
  PricingContent,
  PricingFooter,
  PricingSection
} from 'components/pricing/PricingSection';
import {SEO} from 'components/SEO';
import {PageProps, graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import {links} from 'global.config';
import React, {useEffect} from 'react';
import {PlanLabelsList} from 'components/pricing/PlanLabelsList';
import {PlanDetails} from 'components/pricing/PlanDetails';
import 'keen-slider/keen-slider.min.css';
import {useKeenSlider} from 'keen-slider/react';
import {ScreenSizes} from '../../types/responsive';
import Italic from 'components/Italic';
import {IndexBackground} from 'components/landings/IndexBackground';
import {Content} from 'components/Content';
import {IndexContent} from 'components/landings/IndexContent';
import {Section, SectionActions, SectionHeader} from 'components/Section';
import {SignUpButton} from 'components/SignUpButton';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {IndexImage} from 'components/landings/IndexImage';
import {StyledDiv, StyledImg} from 'components/StyledSx';
import {InternalPageLink} from 'components/links/Pages';
import {GradientBox} from 'components/landings/GradientBox';
import selling from 'images/selling.svg';
import integrated_payments_header_image from 'images/integrated_payments_header_image.png';
import {SupportLink} from 'components/links/Support';
import {FaqsSection} from 'components/FaqsSection';
import {IosDownloadLink} from 'components/DownloadOnAppleStore';
import {AndroidDownloadLink} from 'components/DownloadOnGooglePlay';
import styled from 'styled-components';
import monei_pay_form_en from 'images/monei_pay_form_en.png';
import monei_pay_form_es from 'images/monei_pay_form_es.png';
import {LanguageEnum} from '../../locales/types';
import {GoogleReviews, Place} from 'components/GoogleReviews';

const SectionDescription = styled.p`
  font-weight: 500;
  color: #fafafa;
  font-size: 16px;

  p {
    font-size: 1.25rem;
  }

  a {
    color: #ffffff;
    text-decoration: underline;
  }
`;

const CtaImage = styled.img`
  position: absolute;
  bottom: -6px;
  right: 98px;
  width: 19.5%;

  @media (max-width: ${ScreenSizes.lg}) {
    bottom: 0;
    width: 240px;
    right: 60px;
  }
  @media (max-width: ${ScreenSizes.md}) {
    display: none;
  }
`;

const ReviewsBackground = styled.div`
  position: relative;
  margin-top: 100px;

  &:before {
    display: block;
    content: '';
    z-index: -1;
    height: 70%;
    position: absolute;
    transform: skew(0, -9deg);
    left: 0;
    right: 0;
    bottom: 0;
    top: 20%;
    background: linear-gradient(96.34deg, #00e9d5 0%, #aab4e9 100%);
  }
`;

const Pricing: React.FC<PageProps> = (props) => {
  const {t} = useI18next();
  const {language} = useI18next();
  const isSpanish = [LanguageEnum.es, LanguageEnum.ca].includes(language as LanguageEnum);
  const monei_pay_form = isSpanish ? monei_pay_form_es : monei_pay_form_en;
  const {data} = props;
  const place: Place = {
    rating: data.googlePlacesPlace.rating,
    user_ratings_total: data.googlePlacesPlace.user_ratings_total,
    reviews: data.allGooglePlacesReview.nodes
  };

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    disabled: true,
    loop: false,
    mode: 'snap',
    range: {max: 1},
    slides: {origin: 0, perView: 1.4, spacing: 10},
    breakpoints: {
      ['(max-width: ' + ScreenSizes.md + ')']: {
        slides: {origin: 0, perView: 2.1, spacing: 10},
        disabled: false
      },
      ['(max-width: ' + ScreenSizes.sm + ')']: {
        slides: {origin: 0, perView: 1.7, spacing: 10},
        disabled: false,
        range: {max: 2}
      },
      ['(max-width: ' + ScreenSizes.xs + ')']: {
        slides: {origin: 0, perView: 1.2, spacing: 5},
        disabled: false,
        range: {max: 2}
      }
    }
  });
  const faqs = [
    {
      header: t('How does dynamic billing work?'),
      text: t(
        'As your sales volumes increase, your transaction fees decrease in real time, thanks to MONEI’s dynamic billing system.'
      ),
      content: (
        <Trans>
          As your sales volumes increase, your transaction fees decrease in real time, thanks to
          MONEI’s <SupportLink slug="/articles/360017954318">dynamic billing system</SupportLink>.
        </Trans>
      )
    },
    {
      header: t('How long do payment settlements take?'),
      text: t(
        'Settlement times usually range from 1 to 7 days from the date of the transaction. High-risk merchants — like CBD sellers — have a longer payout period (up to 30 days).'
      ),
      content: (
        <Trans>
          <SupportLink slug="/articles/360017952658">Settlement times</SupportLink> usually range
          from 1 to 7 days from the date of the transaction. High-risk merchants — like{' '}
          <SupportLink slug="/articles/4413886523793">CBD sellers</SupportLink> — have a longer
          payout period (up to 30 days).
        </Trans>
      )
    },
    {
      header: t('Can I issue refunds?'),
      text: t(
        'Yes. You can issue refunds for online payments via your MONEI Dashboard or via your e-commerce backend — learn more here. For physical payments via MONEI Pay, you can issue refunds from your mobile app.'
      ),
      content: (
        <Trans>
          Yes. You can issue refunds for online payments via your{' '}
          <AnchorLink href="https://dashboard.monei.com/" external>
            MONEI Dashboard
          </AnchorLink>{' '}
          or via your e-commerce backend —{' '}
          <SupportLink slug="/articles/360017952778">learn more here</SupportLink>. For physical
          payments via <InternalPageLink slug="monei-pay">MONEI Pay</InternalPageLink>, you can{' '}
          <SupportLink slug="/articles/8418026491537">
            issue refunds from your mobile app
          </SupportLink>
          .
        </Trans>
      )
    },
    {
      header: t('Can I add more than one store to my account?'),
      text: t(
        'Yes. As long as it’s the same business entity, selling the same products, there’s no extra cost to add multiple stores to your MONEI account. '
      ),
      content: (
        <Trans>
          Yes. As long as it’s the same business entity, selling the same products, there’s no extra
          cost to <SupportLink slug="/articles/17482161916049">add multiple stores</SupportLink> to
          your MONEI account.
        </Trans>
      )
    }
  ];

  useEffect(() => {
    if (!instanceRef.current) return;
    instanceRef.current.update();
  }, [instanceRef]);

  return (
    <>
      <SEO
        path="pricing"
        title={t('Pricing for Payment Services')}
        suggestion={t('pricing')}
        description={t(
          'Get affordable payment services for your e-commerce, retail, or hospitality business with the all-in-one omnichannel payments platform. Sign up now ››'
        )}
      />
      <IndexBackground sx={{left: {xl: '50%'}, height: {xl: '1000px'}, width: {xl: '2000px'}}}>
        <Content>
          <IndexContent>
            <SectionHeader underline tagName="h1">
              <Trans>Pricing that scales with your business</Trans>
            </SectionHeader>
            <Trans parent="p">
              Manage all your omnichannel payment needs from a single platform. No setup fees, pay
              lower transaction fees as your business grows, configure many payment methods, and
              much more.
            </Trans>
            <SectionActions>
              <SignUpButton variant="light" style={{marginRight: 15}}>
                <Trans>Open an Account</Trans>
              </SignUpButton>
              <ContactSalesButton />
            </SectionActions>
            <IndexImage
              top="60px"
              width="600px"
              src={selling}
              alt="Pricing"
              title="Pricing"
              className="hide-on-mobile"
            />
          </IndexContent>
        </Content>
      </IndexBackground>
      <PricingBackground>
        <SectionHeader underline style={{color: 'white'}}>
          <Trans>Pricing overview</Trans>
        </SectionHeader>
        <PricingContent>
          <PricingSection>
            <PlansTable>
              <PlanLabelsList />
              <PlansContainer>
                <PlansList ref={sliderRef} className="keen-slider" sx={{overflow: 'visible'}}>
                  {PlanDetails(language).map((plan) => (
                    <Plan key={plan.id} className="keen-slider__slide">
                      {plan.planHeader}
                      {plan.planFeatures}
                      {plan.planAction}
                    </Plan>
                  ))}
                </PlansList>
                <PricingFooter>
                  <p>
                    <Trans parent="b">
                      Available Acquirers or Processors for MONEI PLUS (Payments Orchestration
                      Platform - POP)
                    </Trans>
                    :{' '}
                    <AnchorLink href="https://support.monei.com/hc/en-us/articles/4417643896593-Payments-Orchestration-availability-by-country-or-acquirer p">
                      <Trans>Full updated list</Trans>
                    </AnchorLink>
                    .
                  </p>
                  <p>
                    <AnchorLink href={links.pricing} external>
                      <Trans>See detailed pricing.</Trans>
                    </AnchorLink>
                    <AnchorLink href={links.feels_good} external>
                      <Trans parent="b">
                        {' '}
                        MONEI pricing stands on our Pay What Feels Good Policy.
                      </Trans>
                    </AnchorLink>
                  </p>
                  <Italic>
                    <p id="vat-exempt">
                      <Trans>
                        *According to "artículo 20 de la Ley 37/1992 del IVA" payments related fees
                        are VAT exempt.
                      </Trans>
                    </p>
                  </Italic>
                </PricingFooter>
              </PlansContainer>
            </PlansTable>
          </PricingSection>
        </PricingContent>
      </PricingBackground>
      <Content>
        <Section centered sx={{paddingTop: {all: '3%', sm: '12%'}, gap: '20px'}}>
          <StyledDiv
            sx={{
              marginBottom: '0',
              display: {all: 'flex', sm: 'none'},
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <StyledImg
              src={integrated_payments_header_image}
              alt="Get support"
              title="Get support"
              sx={{width: '80%'}}
            />
          </StyledDiv>
          <div>
            <SectionHeader>
              <Trans>Integrated payments for business platforms and marketplaces</Trans>
            </SectionHeader>
            <Trans parent="p">
              Save time and money by seamlessly integrating payments into your business platform or
              marketplace with <InternalPageLink slug="connect">MONEI Connect</InternalPageLink>.
              Let us manage onboarding, KYC, and payments compliance for you. Get in touch today to
              learn more about MONEI Connect.
            </Trans>
            <div></div>
            <ContactSalesButton variant="dark" />
          </div>
        </Section>
      </Content>
      <ReviewsBackground>
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          <SectionHeader underline sx={{textAlign: 'center'}}>
            <Trans>What MONEI merchants are saying</Trans>
          </SectionHeader>
        </div>
        <GoogleReviews place={place} />
      </ReviewsBackground>
      <Content style={{marginTop: '100px'}}>
        <GradientBox wrapperStyle={{overflow: 'visible'}}>
          <SectionHeader tagName="h3" underline style={{color: 'white'}}>
            <Trans>Power your omnichannel payments with MONEI</Trans>
          </SectionHeader>
          <SectionDescription>
            <Trans parent="p">
              Get started today (without obligation) and download your mobile payment app on one or
              many <IosDownloadLink>iOS</IosDownloadLink> or{' '}
              <AndroidDownloadLink>Android</AndroidDownloadLink> mobile devices.
            </Trans>
          </SectionDescription>
          <SectionActions>
            <SignUpButton variant="dark">
              <Trans>Open an Account</Trans>
            </SignUpButton>
          </SectionActions>
          <CtaImage src={monei_pay_form} />
        </GradientBox>
      </Content>
      <Content>
        <Section centered column>
          <FaqsSection faqs={faqs} title={<Trans>Pricing FAQs</Trans>} />
        </Section>
      </Content>
    </>
  );
};

export default Pricing;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "pricing"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    googlePlacesPlace {
      user_ratings_total
      rating
    }
    allGooglePlacesReview(sort: {fields: rating, order: DESC}, filter: {rating: {gt: 2}}) {
      nodes {
        id
        rating
        text
        relative_time_description
        profile_photo_url
        author_name
      }
    }
  }
`;
